import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: '',
      email: '',
      type: '',
      permissions: [],
      access: [],
      company: {}
    },
    selectedCompany: null,
    loggedIn: false
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      state.loggedIn = true
    },
    REMOVE_USER(state) {
      state.user = {
        name: '',
        email: '',
        type: '',
        permissions: [],
        access: [],
        company: {}
      }
      state.loggedIn = false
    },
  },
  actions: {
    TWO_FACTOR_AUTH({commit}, data) {
      return axios.post('/users/two-factor-login', data)
        .then(res => {
          localStorage.setItem('samuel-clients', res.data.data.token);
          commit('SET_USER', res.data.data)
          return res
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    LOGIN_USER({commit}, data) {
      return axios.post('/users/login', data)
        .then(res => {
          //localStorage.setItem('samuel-clients', res.data.data.token);
          commit('SET_USER', res.data.data)
          return res
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    LOGOUT_USER({commit}) {
      return axios.post('/users/logout')
        .then(res => {
          localStorage.removeItem('samuel-clients');
          commit('REMOVE_USER')
          return res
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    GET_PROFILE({commit}) {
      return axios.get('/users/me')
        .then(res => {
          commit('SET_USER', res.data)
          return res
        })
    },
  },
  modules: {
  }
})
