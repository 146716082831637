import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import analyticsServices from "../services/analytics";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      return 'login'
    },
  },
  {
    path: '/:slug/home',
    name: 'slug-home',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Login.vue')
    }
  },
  {
    path: '/:slug/documents',
    name: 'slug-documents',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Documents.vue')
    }
  },
  {
    path: '/:slug/file-system',
    name: 'slug-file-system',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/FileSystem3.vue')
    }
  },
  {
    path: '/:slug/events',
    name: 'slug-events',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Events.vue')
    }
  },
  // {
  //   path: '/:slug/reports',
  //   name: 'slug-reports',
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/Reports.vue')
  //   }
  // },
  {
    path: '/:slug/about',
    name: 'slug-about',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/:slug/notifications',
    name: 'slug-notifications',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Notifications.vue')
    }
  },
  {
    path: '/:slug/reports',
    name: 'slug-reports',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Reports.vue')
    }
  },
  {
    path: '/:slug/invoices',
    name: 'slug-invoices',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Invoice.vue')
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Companies.vue')
    }
  },
  {
    path: '/company/:id/users',
    name: 'company-id-users',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Users.vue')
    }
  },
  {
    path: '/help',
    name: 'help',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Help.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
    }
  },
  {
    path: '/users',
    name: 'users',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AllUsers.vue')
    }
  },
  {
    path: '/:slug/analytics/user/:id',
    name: 'slug-analytics-user-id',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Analytics.vue')
    }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AnalyticsAll.vue')
    }
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let freeRoute = ['privacy', 'help'];
  if(to.name.includes('login')) {
    next()
  }
  else if(!store.state.loggedIn && freeRoute.every(page => !to.name.includes(page))) {
    store.dispatch('GET_PROFILE').then(res => {
      analyticsServices.store({type: 'access', params: []})
      next()
    }).catch(() => {
      next({ name: 'login' })
    })
  }
  else {
    next()
  }
})

export default router
