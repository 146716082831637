<template>
  <v-app id="inspire">
    <router-view/>
  </v-app>
</template>


<script>
export default {
  created(){
    document.title = 'Samuel Client Portal'
  }
}
</script>

<style>
  @font-face {
    font-family: "HelveticaNeue";
    src: local("HelveticaNeue"),
    url(../public/fonts/HelveticaNeueLight.ttf) format("truetype"),
    url(../public/fonts/HelveticaNeueLight.otf) format("otf")
  }
  .title-client {
    font-family: HelveticaNeue;
    font-size: 20px
  }
</style>
