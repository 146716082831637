import axios from 'axios'
import cfg from '../config'
import router from "../router";

let instance = axios.create({baseURL: cfg.api});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('samuel-clients');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

  return config;
});

instance.interceptors.response.use(undefined, function (err) {
    if (err.response && err.response.status === 401 && err.response.data.message === 'Unauthenticated.') {
      router.replace('/login')
    }
    return Promise.reject(err);
});

export default instance;


